import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InformationCard(props) {
  const isImage = typeof props.icon === "string";

  return (
    <div className="info-cards">
      <span className="info-card-icon">
      {isImage ? (
          <img src={props.icon} alt={props.title} className="info-fa-icon" width="80%" height="80%" />
        ) : (
          <FontAwesomeIcon className="info-fa-icon" icon={props.icon} />
        )}      </span>
      <p className="info-card-title">{props.title}</p>
      <p className="info-card-starting">Market Price*</p>
      <span className="info-card-marketPrice">{props.marketPrice}</span>
      <p className="info-card-our">Our Price</p>
      <p className="info-card-ourPrice">{props.ourPrice}</p>
      <p className="info-card-description">{props.description}</p>
    </div>
  );
}

export default InformationCard;
