import React from "react";
import LegalDocs from "../Components/LegalDocs";
import Navbar from "../Components/Navbar";

function Legal() {
  return (
    <>
      <Navbar />
      <LegalDocs />
    </>
  );
}

export default Legal;
