import React from 'react';
import { Collapse } from 'antd';
import { Tabs } from 'antd';
import "../Styles/Faq.css";
import Footer from './Footer';

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;


// add "<Collapse defaultActiveKey="1" items={itemsNest} />" to children to view as nested collapse
//General items for FAQ
const generalitems = [
    {
        key: '1',
        label: 'What is UpDoc?',
        children: 'UpDoc is a website that allows you to find a doctor that can help you with your medical needs.',
    },
    {
        key: '2',
        label: 'Where will my treatment be done?',
        children: 'Your treatments will be done at UpDoc experience centers. Our experience centers are partner clinics that are thoroughly reviewed and certified by UpDoc, you can choose an experience center nearest to you for your convenience.',
    },
    {
        key: '3',
        label: 'How are the treatments affordable?',
        children: 'We directly connect experienced dental consultants to you, so that you can avail quality dental treatment at affordable prices at your nearest UpDoc experience center.',
    },
];
//RCT items for FAQ
const rctitems = [
    {
        key: '1',
        label: 'Are root canals painful?',
        children: 'Root canals are painless thanks to the local anesthesia. You will experience a little bit of pain while taking the anesthesia but the actual treatment is painless after that. The pain associated with root canals comes from the infected tooth causing pain before the treatment. Removing the infection with a root canal removes the pain.',
    },
    {
        key: '2',
        label: 'Are root canal teeth strong?',
        children: 'Though root canals save teeth from decay, they cannot restore teeth to their former strength. A dentist or endodontist usually places a cap or crown after the procedure for reinforcement and aesthetic purposes. The crown won’t affect your eating habits or your daily routines once it is placed.',
    },
    {
        key: '3',
        label: 'Can root canals be avoided?',
        children: 'Maintaining good oral hygiene by brushing your teeth twice a day or after meals is the best way to avoid getting a cavity or infection. Once infected, the tooth needs to be treated using a root canal or other effective treatments.',
    },
    {
        key: '4',
        label: 'Can root canal be done in one sitting?',
        children: 'In pursuit of patient convenience, advanced technology and tools have made it possible to complete the root canal in a single sitting without compromising on quality. Some rare cases still require more than a single sitting.',
    },
    {
        key: '5',
        label: 'Can root canal be done on wisdom tooth?',
        children: 'Root Canals can be done on wisdom teeth if we want to save the tooth, or the wisdom tooth is extracted.',
    },
    {
        key: '6',
        label: 'Why root canal is required?',
        children: 'A root canal is required when the pulp inside the tooth is inflamed or infected with bacteria. This mostly occurs in the case of cavities and tooth decay.',
    },
    {
        key: '7',
        label: 'Which root canal cap is the best?',
        children: 'There are various crowns used after root canal treatments - Zirconia, Ceramic, and metal crowns are mostly used in India. Zirconia is the best strength-wise and also provides the best color matching with other teeth, but also tends to be more expensive compared to the others.',
    },
    {
        key: '8',
        label: 'What is Root canal treatment cost with cap',
        children: <p>Please refer npmour <a href="/treatments#RootCanal">Treatments</a> page</p>,
    },
    {
        key: '9',
        label: 'Can you eat after root canal?',
        children: <ul>
            <li>Don't Eat or drink anything for 30 mins until the cement solidifies</li>
            <li>Dont talk or chew if the treatment is done on the lower jaw till the Anastasia wears down ( 3-6 hours ) to avoid biting ur own tongue or lip.</li>
            <li>Don't chew any hard food on the side of the treatment until the crown is placed on the tooth.</li>
            </ul>,
    }
];
//Surgical Procedures items for FAQ
const surgicalitems = [
    {
        key: '1',
        label: 'Will wisdom tooth pain go away?',
        children: 'You will experience pain if a wisdom teeth is erupting, which will go away with time. But if the tooth is infected or has no space to erupt, the pain will last till the tooth is removed.',
    },
    {
        key: '2',
        label: 'Is wisdom tooth pain normal?',
        children: 'If the pain is due to the wisdom tooth erupting, it is absolutely normal.',
    },
    {
        key: '3',
        label: 'Can wisdom tooth pain cause headache or fever?',
        children: 'Wisdom Tooth pain can cause headaches depending on the severity of the pain.',
    },
    {
        key: '4',
        label: 'Is wisdom tooth extraction painful?',
        children: 'The procedure itself is not painful because it is done under local anesthesia. After the treatment, however, you will experience discomfort while opening your mouth for a few days.',
    }
];
//Aligners items for FAQ
const aligneritems = [
    {
        key: '1',
        label: 'Are aligners better than braces?',
        children: 'Aligners are better if u want a nearly invisible and comfortable teeth straightening method. However, braces are a better option in case of severe crowding of other severe conditions such as overbite and underbite.',
    },
    {
        key: '2',
        label: 'Are aligners painful?',
        children: 'There is minor discomfort when you start wearing a new set of aligners. Most patients describe the pain as pressure or tenderness. Often, the discomfort is only noticed while wearing the aligners or triggered when you take them in and out to eat and clean.',
    },
    {
        key: '3',
        label: 'Can aligners straighten teeth?',
        children: 'Aligners are used to straighten teeth and are also preferred by most patients these days over traditional braces because they are almost invisible and easier to clean.',
    },
    {
        key: '4',
        label: 'Can aligners fix overbite?',
        children: 'Aligners can be used to fix both overbite and underbite in many cases, but they cannot provide satisfactory results in severe cases.',
    },
    {
        key: '5',
        label: 'Can aligners straighten teeth?',
        children: 'Aligners are used to straighten teeth and are also preferred by most patients these days over traditional braces because they are almost invisible and easier to clean.',
    },
    {
        key: '6',
        label: 'Will aligners change my face?',
        children: 'Aligners and braces are orthodontic treatments that don\'t change your facial structure, such as your jaw line. They also don\'t directly impact your nose or make your face longer. However, you might experience a minor change in your face.',
    },
    {
        key: '7',
        label: 'What happens if you don\’t wear your aligners?',
        children: 'Your teeth can have somewhat moved back to their original position if you haven\'t worn your aligners for a long time. Your present aligners might not fit you when you wear them again. You should contact your dentist in this situation. You might be able to wear your old aligners until your subsequent appointment.',
    },
];


const tabitems = [
    {
        key: "1",
        label: "General",
        children: <Collapse bordered={true} defaultActiveKey={["1"]} items={generalitems} />,
    },
    {
        key: "2",
        label: "Root Canal",
        children: <Collapse defaultActiveKey={["1"]} bordered={true} items={rctitems} />,
    },
    {
        key: "3",
        label: "Aligners",
        children: <Collapse defaultActiveKey={["1"]} items={aligneritems} />,
    },
    {
        key: "4",
        label: "Surgical Procedures",
        children: <Collapse defaultActiveKey={["1"]} items={surgicalitems} />,
    }
];

const FaqComponent = () => {

    return (
        <div className='faq-full'>
            <section className='faq-content'>
                <Tabs
                    defaultActiveKey="1"
                    tabPosition='top'
                    style={{
                        height: 300,
                    }}
                    items={tabitems}
                />  
            </section>
        </div>
    );
};
export default FaqComponent;