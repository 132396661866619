// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBaNwZApQf7f_4YRXhTwq5aQpAw5zY_w0g",
  authDomain: "updoc-88ee2.firebaseapp.com",
  projectId: "updoc-88ee2",
  storageBucket: "updoc-88ee2.appspot.com",
  messagingSenderId: "785688456846",
  appId: "1:785688456846:web:1e1ddcf878545abcafa3df",
  measurementId: "G-03XQDJ5KDN",
  databaseURL:"https://updoc-88ee2-default-rtdb.asia-southeast1.firebasedatabase.app/"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
export {database, auth, analytics};
export default firebaseConfig;