import React from 'react'
import Navbar from '../Components/Navbar'
import TreatmentsComponent from '../Components/TreatmentsComponent'
import { Helmet } from 'react-helmet';
const Treatments = () => {
    return (
        <div>
            <Helmet>
                <title>UpDoc Treatments</title>
                <meta name="description" content="UpDoc is a platform that allows you to book appointments with doctors" />
                <meta name="keywords" content="updoc, doctor near me, dentist near me, Best dentist in kondapur, Aligners, Root canals, Orthodontics, Crowns, Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants" />
                <meta name="author" content="UpDoc" />
            </Helmet>
            <Navbar />
            <div style={{ paddingTop: '50px' }}>
                <TreatmentsComponent />
            </div>
        </div>
    );
};

export default Treatments