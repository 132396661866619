import React from "react";
import DoctorCard from "./DoctorCard";
import DrAyesha from "../Assets/Doctors/Dr-Ayesha-Tehreem.avif";
import DrBalaji from "../Assets/Doctors/Dr-Dama-Balaji.avif";
import DrKiran from "../Assets/Doctors/Dr-K-Kiran-Kumar.avif";
import DrSuresh from "../Assets/Doctors/Dr-P-L-Suresh.avif";
import DrBhavani from "../Assets/Doctors/Dr-Bhavani-Panithini.avif";
import "../Styles/Doctors.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Doctors() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Meet Our Doctors</span>
        </h3>
      </div>

      <div>
        <Slider {...settings}>
            <div className="box dt-cards-content" >
              <DoctorCard
                img={DrKiran}
                name="Dr. K Kiran Kumar"
                title="Orthodontist"
              // stars="4.9"
              // reviews="1800"
              />
            </div>
            <div className="box dt-cards-content">
              <DoctorCard
                img={DrBhavani}
                name="Dr. Bhavani Panithini"
                title="Endodontist"
              // stars="4.8"
              // reviews="500"
              />
            </div>
            <div className="box dt-cards-content">
              <DoctorCard
                img={DrBalaji}
                name="Dr. Dama Balaji"
                title="Endodontist"
              // stars="4.8"
              // reviews="500"
              />
            </div>
            <div className="box dt-cards-content">
              <DoctorCard
                img={DrSuresh}
                name="Dr. P L Suresh"
                title="Maxillofacial Surgeon"
              // stars="4.7"
              // reviews="450"
              />
            </div>
            <div className="box dt-cards-content">
              <DoctorCard
                img={DrAyesha}
                name="Dr. Ayesha Tehreem"
                title="Endodontist"
              // stars="4.9"
              // reviews="1800"
              />
            </div>
        </Slider>
      </div>
    </div>
  );
}
export default Doctors;