import React from "react";
import DoctorPageCard from "./DoctorPageCard";
import DrAyesha from "../Assets/Doctors/Dr-Ayesha-Tehreem.avif";
import DrBalaji from "../Assets/Doctors/Dr-Dama-Balaji.avif";
import DrKiran from "../Assets/Doctors/Dr-K-Kiran-Kumar.avif";
import DrSuresh from "../Assets/Doctors/Dr-P-L-Suresh.avif";
import DrBhavani from "../Assets/Doctors/Dr-Bhavani-Panithini.avif";
import "../Styles/DoctorPage.css"

function Doctor_Page() {
    return (
        <div className="doctor-section" id="doctors">
            <div className="doc-title-content">
                <h3 className="doc-title">
                    <span>Meet Our Doctors</span>
                </h3>
            </div>
            <div>
                <div className="box doc-cards-content">
                    <DoctorPageCard
                        img={DrKiran}
                        name="Dr. K Kiran Kumar"
                        title="Orthodontist"
                        stars="4.9"
                        reviews="1800"
                        review="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a nunc quis augue volutpat mollis. Cras semper ultrices est nec luctus. Nullam eu consectetur nibh. Integer tristique velit "
                        reviewer="Meep"
                    />
                </div>
                <div className="box doc-cards-content">
                    <DoctorPageCard
                        img={DrBhavani}
                        name="Dr. Bhavani Panithini"
                        title="Endodontist"
                        stars="4.8"
                        reviews="500"
                    />
                </div>
                <div className="box doc-cards-content">
                    <DoctorPageCard
                        img={DrBalaji}
                        name="Dr. Dama Balaji"
                        title="Endodontist"
                        stars="4.8"
                        reviews="500"
                    />
                </div>
                <div className="box doc-cards-content">
                    <DoctorPageCard
                        img={DrSuresh}
                        name="Dr. P L Suresh"
                        title="Maxillofacial Surgeon"
                        stars="4.7"
                        reviews="450"
                    />
                </div>
                <div className="box doc-cards-content">
                    <DoctorPageCard
                        img={DrAyesha}
                        name="Dr. Ayesha Tehreem"
                        title="Endodontist"
                        stars="4.9"
                        reviews="1800"
                    />
                </div>
        </div>
    </div >
  );
}

export default Doctor_Page;
