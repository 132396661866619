import {format} from "date-fns";
function PreviousFrames(props){
    const {application} = props;
    return(
        <li key={application.id}>
            <div className="Previous-Appointment-info">
                <p>
                    <b>Date: </b> {format(application.date,"dd-MM-yyyy")}
                </p>
                <p>
                    <b>Time:</b> {application.time}
                </p>
                <p>
                    <b>Doctor:</b> {application.docterName}
                </p>
                <p>
                    <b>Treatment Remarks: </b> {application.remarks}
                </p>
            </div>
        </li>
    );
}
export default PreviousFrames;