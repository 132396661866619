import React from 'react';
import FaqComponent from '../Components/FaqComponent';
import Navbar from '../Components/Navbar';
import { Helmet } from 'react-helmet';

const FAQ = () => {
  return (
    <div>
      <Helmet >
        <title>UpDoc FAQ</title>
        <meta name="description" content="FAQ about UpDoc" />
        <meta name="keywords" content="faq, updoc, faq updoc" />
        <meta name="author" content="UpDoc" />
      </Helmet>
      <Navbar />
      <FaqComponent />
    </div>
  );
};

export default FAQ