import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Legal from "./Pages/Legal";
import NotFound from "./Pages/NotFound";
import Appointment from "./Pages/Appointment";
import FAQ from "./Pages/FAQ";
import Treatments from "./Pages/Treatments";
import Doctors from "./Pages/Doctors";
import About from "./Pages/About";
import { UserAuthContextProvider } from "./contexts/AuthContext";
import SignupPage from "./Pages/SignupPage";
import LoginPage from "./Pages/LoginPage";
import DashboardClient from "./Pages/DasboardClient";
import ScrollToHashElement from "./Scripts/ScrollToHashElement";
import ProtectedRoute from "./Components/Auth/PrivateRoute";



function App() {
  return (
    <div className="App">
      <Router basename="/">
        <ScrollToHashElement />
        <UserAuthContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home/:id" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/treatments" element={<Treatments />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/about" element={<About />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<DashboardClient />} />
              <Route path="/appointment" element={<Appointment />} />
            </Route>
          </Routes>
        </UserAuthContextProvider>
      </Router>
    </div>
  );
}

export default App;
