import { tokens } from "../Theme";

export const timings={
    "10:00AM": {status: "Open"},
    "10:30AM": {status: "Open"},
    "11:00AM": {status: "Open"},
    "11:30AM": {status: "Open"},
    "12:00PM": {status: "Open"},
    "12:30PM": {status: "Open"},
    "1:00PM": {status: "Open"},
    "1:30PM": {status: "Open"},
    "2:00PM": {status: "Open"},
    "2:30PM": {status: "Open"},
    "3:00PM": {status: "Open"},
    "3:30PM": {status: "Open"},
    "4:00PM": {status: "Open"},
    "4:30PM": {status: "Open"},
    "5:00PM": {status: "Open"},
    "5:30PM": {status: "Open"},
    "6:00PM": {status: "Open"},
    "6:30PM": {status: "Open"},
    "7:00PM": {status: "Open"},
    "7:30PM": {status: "Open"},
};