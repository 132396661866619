import React from "react";
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { DocReviews } from "../Scripts/DocReviews";

function DoctorPageCard(props) {
    let rMessage, rName, rLocation;
    const reviewsLength = DocReviews.length - 1;
    const [review, setReview] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        // Update window width on resize
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    // back to previous review
    const backBtnClick = () => {
        setReview(review <= 0 ? reviewsLength : review - 1);
        handleReviewsUpdation();
    };

    // go to newer review
    const frontBtnClick = () => {
        setReview(review >= reviewsLength ? 0 : review + 1);
        handleReviewsUpdation();
    };
    const device = windowWidth > 600 ? "desk" : "phone";
    const handleReviewsUpdation = () => {
        const reviewMessage = DocReviews[review];
        rName = reviewMessage.name;
        rLocation = reviewMessage.location;
        rMessage = reviewMessage.message;
    };
    handleReviewsUpdation();
    return (
        (device === "desk" ? (
            <>
                <div className="doc-card">
                    <div className="doc-image">
                        <img src={props.img} alt={props.name} className="doc-card-img" />
                    </div>
                    <div className="doc-description">
                        <p className="doc-card-name">{props.name}</p>
                        <div className="doc-card-title-info">
                            <p className="doc-card-title">{props.title}</p>
                            <p className="doc-card-stars">
                                <FontAwesomeIcon
                                    icon={faStar}
                                    style={{ color: "#F7BB50", paddingRight: "6px" }}
                                />
                                {props.stars}
                                <span className="doc-card-reviews"> ({props.reviews}+ Reviews)</span>
                            </p>
                        </div>
                        <p className="description-text">{rMessage}<br></br><b> ~ {rName}</b> </p>
                        <div className="rw-btns">
                            <button
                                className="doc-rw-back-btn"
                                type="button"
                                onClick={backBtnClick}
                            >
                                ←
                            </button>
                            <button
                                className="doc-rw-next-btn"
                                type="button"
                                onClick={frontBtnClick}
                            >
                                →
                            </button>
                        </div>
                        <button className="doctor-bookApp-button">Book appointment</button>
                    </div>
                </div>
            </>
        ) : (
            <>
                <div className="doc-card">
                    <div className="doc-image-container">
                        <img src={props.img} alt={props.name} className="doc-card-img" />
                        <div className="doc-card-title-info">
                            <p className="doc-card-name">{props.name}</p>
                            <p className="doc-card-title">{props.title}</p>
                            <p className="doc-card-stars">
                                <FontAwesomeIcon
                                    icon={faStar}
                                    style={{ color: "#F7BB50", paddingRight: "6px" }}
                                />
                                {props.stars}
                                <span className="doc-card-reviews"> ({props.reviews}+ Reviews)</span>
                            </p>
                        </div>
                    </div>
                    <div className="doc-description">
                        <p className="description-text">{rMessage}<br></br><b> ~ {rName}</b> </p>
                        <div className="doc-rw-btns">
                            <button
                                className="doc-rw-back-btn"
                                type="button"
                                onClick={backBtnClick}
                            >
                                ←
                            </button>
                            <button
                                className="doc-rw-next-btn"
                                type="button"
                                onClick={frontBtnClick}
                            >
                                →
                            </button>
                        </div>
                        <button className="doctor-bookApp-button">Book appointment</button>
                    </div>
                </div>
            </>
        ))

    );
}

export default DoctorPageCard;
