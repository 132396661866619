import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar";
import NotFoundComponent from "../Components/NotFoundComponent";

function NotFound() {
  const deployedURL = window.location.href;
  const location = useLocation();

  return (
    <>
    <Navbar />
    <NotFoundComponent />
    </>
  );
}

export default NotFound;
