import React from "react";
import { Container} from "react-bootstrap";
import Navbar from "../Components/Navbar.js";
import Login from "../Components/Auth/Login.js";
import "bootstrap/dist/css/bootstrap.min.css";


function LoginPage() {
      return(
        <>
        <Navbar/>
        <Login/>
        </>
      );
}

export default LoginPage;
