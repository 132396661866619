import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../Styles/Hero.css";
import "../Styles/AppointmentForm.css";
import LoginPhno from "./Auth/LoginPhno.js";
import { useUserAuth } from "../contexts/AuthContext.js";
import heroimg from "../Assets/hero-img.avif";
// import popUpimg from "../Assets/popUpBanner.png"
// import Time from 'react-time';
// import Popup from "./Popup.js";


function Hero() {
  const [goUp, setGoUp] = useState(false);
  const [popup, setPopup] = useState(true);
  const { user } = useUserAuth();
  const [timedPopup,setTimedPopup]=useState(false);

  useEffect(()=>{
    setTimeout(()=>{
      setTimedPopup(true);
    },5000);
  },[]);

  function openForm() {
    setPopup(false);
    document.getElementById("myForm").style.display = "block";
    document.getElementById("popup-button").innerHTML = "Close";
  }
  const handleDirections = () => {
    window.open("https://maps.app.goo.gl/CMmS7ARte4S895Cm7", "_blank");
  };
  function closeForm() {
    setPopup(true);
    document.getElementById("myForm").style.display = "none";
    document.getElementById("popup-button").innerHTML = "Book FREE Appointment";
    window.location.reload();
  }
  function openGreenDentBtn1() {
    document.getElementById("greenButtonPopup1").style.display = "block";
  }
  function closeGreenDentBtn1() {
    document.getElementById("greenButtonPopup1").style.display = "none";
  }
  function openGreenDentBtn2() {
    document.getElementById("greenButtonPopup2").style.display = "block";
  }
  function closeGreenDentBtn2() {
    document.getElementById("greenButtonPopup2").style.display = "none";
  }
  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 0) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);
  const Ref = useRef(null);
 
  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
      const total =
          Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor(
          (total / 1000 / 60) % 60
      );
      const hours = Math.floor(
          (total / 1000 / 60 / 60) % 24
      );
      return {
          total,
          hours,
          minutes,
          seconds,
      };
  };

  const startTimer = (e) => {
      let { total, hours, minutes, seconds } =
          getTimeRemaining(e);
      if (total >= 0) {
          // update the timer
          // check if less than 10 then we need to
          // add '0' at the beginning of the variable
          setTimer(
              (hours > 9 ? hours : "0" + hours) +
              ":" +
              (minutes > 9
                  ? minutes
                  : "0" + minutes) +
              ":" +
              (seconds > 9 ? seconds : "0" + seconds)
          );
      }
  };

  const clearTimer = (e) => {
      // If you adjust it you should also need to
      // adjust the Endtime formula we are about
      // to code next
      setTimer("04:08:10");

      // If you try to remove this line the
      // updating of timer Variable will be
      // after 1000ms or 1sec
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
          startTimer(e);
      }, 1000);
      Ref.current = id;
  };

  const getDeadTime = () => {
      let deadline = new Date();

      // This is where you need to adjust if
      // you entend to add more time
      deadline.setSeconds(46350);
      return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
      clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
      clearTimer(getDeadTime());
  };

  return (
    <div className="section-container" id="hero">
      <div className="hero-section">
        <div className="hero-text-section" >
          <div className="hero-text-section-banner">
            <div className="hero-text-section-banner1">
              <h3>Free</h3>
              <h4>Online/Offline Consultation</h4>
              <button className="hero-button1"
                id="hero-image-text-a-btn"
                onClick={openGreenDentBtn1}>
                <a>Learn more...</a>
              </button>
            </div>
            <div className="hero-text-section-heading-container">
              <div className="hero-text-section-heading">
                <p>upto&nbsp;</p>
                <h3>40%</h3>
              </div>
              <div className="hero-text-section-sub">
                <h4>Lower Prices on Treatments</h4>
                <button className="hero-button2"
                  id="hero-image-text-a-btn"
                  onClick={openGreenDentBtn2}>
                  <a>Find out How...</a>
                </button>
              </div>
            </div>
          </div>
          <div className="hero-text-section-content">
            <div className="hero-text-section-content-img">
              <img src={heroimg} className="hero-image-bg"></img>
            </div>
            <div className="hero-text-section-content-p">
              <h3><b>Exceptional Quality</b> at these Prices,<br></br> All treatments exclusively by <b>specialists</b>,<br></br>Make The Obvious Choice!</h3>
            </div>
          </div>
        </div>


        <section className="hero-bookApp-section">
          {user ? (
            <>
              <div className="heroDashboard">
                <Link to="/dashboard">
                  <h2 style={{ color: "#37B34A" }}>Hi, {user.firstName} Head over to Dashboard to
                    see your appointment details...&nbsp;
                    <FontAwesomeIcon icon={faUpRightFromSquare} style={{ fontSize: "14px" }} />
                  </h2>
                </Link>
                <p> or </p>
                <Link to="/appointment">
                  <h2>Click here to Book a Free Appointment...&nbsp;
                    <FontAwesomeIcon icon={faUpRightFromSquare} style={{ fontSize: "14px" }} />
                  </h2>
                </Link>
              </div>
            </>
          ) : (
            <>
              {<LoginPhno />}
            </>
          )

          }

        </section>

      </div>
      <div
        id="popup-button"
        onClick={popup ? openForm : closeForm}
        className={`scroll-up ${goUp ? "show-scroll" : "show-scroll"}`
        }
        type="button"

      >
        <FontAwesomeIcon icon={faCalendarCheck} bounce /> &nbsp; Book FREE Appointment
      </div>

      <div id="myForm" className="register-popup">
        <LoginPhno />
      </div>
      <div id="greenButtonPopup1" className="greenButtonPopup">
        <h3>Yes every check-up at UpDoc is Free!</h3>
        <p>
          You can talk to a dentist from the comfort of your home,
          upload all your x-rays, or get them for free when you come for an in-clinic check-up.
          <br></br>
          You only pay for your Treatment.
        </p>
        <button className="greenButtonPopup-CloseBtn" onClick={closeGreenDentBtn1}>Close</button>
      </div>
      <div id="greenButtonPopup2" className="greenButtonPopup">
        <h3>Yes! It's True!</h3>
        <p>We cut our overheads, thanks to our innovative <b>Asset-Lite</b> model.</p>
        <p>We rent out Clinics that meet our high standards, and collaborate with consultants
          to give you the best treatment experience at the most affordable prices.
        </p>
        <button className="greenButtonPopup-CloseBtn" onClick={closeGreenDentBtn2}>Close</button>
      </div>
      {/* <Popup trigger={timedPopup} setTrigger={setTimedPopup} className="banner-popUp">
        <h3>UpDoc Ad...........</h3>
        <h2>{timer}</h2>
      </Popup> */}
    </div >
  );
}

export default Hero;