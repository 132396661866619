export const customerReviews = [
    {
      "name": "Prakriti Yadav",
      "location": "Hyderabad, India",
      "message": "I recently had the pleasure of using Updoc for my dental needs and I must say, I am thoroughly impressed with their service. From start to finish, my experience with Updoc was exceptional and I highly recommend them for anyone in need of dental care."
    },
    {
      "name": "Roshan Shikavanth",
      "location": "Hyderabad, India",
      "message": "UpDoc is an innovative platform that bridges the gap between patients seeking dental care and experienced dental consultants. By leveraging technology, UpDoc empowers individuals to access affordable and high-quality dental treatments."
    },
    {
      "name": "Aashish Vennapusa",
      "location": "Hyderabad, India",
      "message": "Booking appointments was a breeze, and the service exceeded my expectations. The doctors truly listen and provide effective solutions. 5 stars!"
    },
    {
      "name": "Pranav Kiran",
      "location": "Hyderabad, India",
      "message": "First and foremost, the staff at Updoc were incredibly friendly and welcoming. They made me feel comfortable and at ease from the moment I walked in. The waiting area was clean and modern, and I was seen by the dentist in a timely manner."
    },
    {
      "name": "Maurice D Moss",
      "location": "Hyderabad, India",
      "message": "The dentist at Updoc was highly skilled and knowledgeable. They took the time to thoroughly explain my treatment options and answered all of my questions. I felt like they genuinely cared about my oral health and were not just trying to push unnecessary procedures."
    },
    {
      "name": "Jennifer Maria",
      "location": "Hyderabad, India",
      "message": "The facilities at Updoc were state-of-the-art and well-maintained. I could tell that they take pride in providing the best possible care for their patients. The equipment was modern and the entire office had a clean and professional atmosphere."
    },
    {
      "name": "Harshitha Devarkonda",
      "location": "Hyderabad, India",
      "message": "One thing that stood out to me about Updoc was their focus on patient comfort. They provided me with a blanket and headphones during my procedure, which helped me to relax. The staff also checked in with me regularly to make sure I was comfortable and not experiencing any pain."
    },
    {
      "name": "Hardik Yadav",
      "location": "Hyderabad, India",
      "message": "In terms of pricing, Updoc was very competitive with other dental service providers in the area. They also offer various payment options and work with insurance providers, making it easier for patients to afford necessary treatments."
    },
    {
      "name": "Mukundan Chariar",
      "location": "Hyderabad, India",
      "message": "Overall, I highly recommend Updoc for anyone in need of dental services. From their friendly and knowledgeable staff to their top-notch facilities, Updoc truly stands out as a premier dental service provider. I will definitely be returning for my future dental needs."
    },
    {
      "name": "Aaryan Iyer",
      "location": "Hyderabad, India",
      "message": "UpDoc partners with reputable dental clinics that adhere to strict quality standards. Patients can rest assured that the recommended treatments are performed by skilled dentists using advanced techniques and materials. The platform also facilitates patient reviews, enabling users to make informed decisions based on the experiences of others."
    }
];
