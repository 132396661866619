import React from 'react'
import Navbar from '../Components/Navbar'
import AboutComponent from '../Components/About'
import { Helmet } from 'react-helmet';
const About = () => {
  return (
    <div>
      <Helmet >
        <title>About</title>
        <meta name="description" content="About UpDoc" />
        <meta name="keywords" content="about, updoc, about us, about updoc" />
        <meta name="author" content="UpDoc" />
      </Helmet>
      <Navbar />
      <div style={{paddingTop: '64px'}}>
        <AboutComponent />
      </div>
    </div>
  );
};

export default About