import React from "react";
import { useState, useEffect } from 'react';
import "../Styles/TreatmentsComponent.css"
import { Tabs } from "antd";
import aligner from "../Assets/Aligners/aligners.avif"
import Zirconia from "../Assets/RCT/zirconia-crowns.avif"
import Ceramic from "../Assets/RCT/metal-ceramic-crowns.avif"
import Metal from "../Assets/RCT/metal-crowns.avif"
import RCT from "../Assets/RCT/rct-procedure.avif"
import implant from "../Assets/Surgical procedures/dental-implants.webp"
import impaction from "../Assets/Surgical procedures/impactions.avif"
import extraction from "../Assets/Surgical procedures/extraction.avif"

function TreatmentsComponent() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [tab, setTab] = useState(window.location.hash.slice(1))
  useEffect(() => {
    window.addEventListener("hashchange", () => {
      const hash = window.location.hash.slice(1);
      setTab(hash);
    });
  }, []);

  const onChange = (key) => {
    setTab(key);
  };

  useEffect(() => {
    window.location.hash = tab;
  }, [tab]);

  const tabPosition = windowWidth > 1000 ? "left" : "top";

  const rctitems = (
    <>
      <h3 className="treatment-specialist">Specialist: Endodontist</h3>
      <h3 className="treatment-priceMK-head">Market Price: <p>Rs. 5,500</p> </h3>
      <h3 className="treatment-priceUD-head">Our Price: <p>Rs. 3,999</p></h3>
      <hr className="treatments-hr"/>
      <img className="rct-main-image" src={RCT} alt="RCT" />
      <p className="treatment-description">A root canal is done when the pulp(innermost part of
        the tooth) gets infected.This typically occurs due to cavitiess.A root
        canal is done under local anesthesia. After the root canal is done, a cap
        or crown is placed over the tooth to strengthen it.
      </p>
      <h4 className="treatment-case">Types of crowns -</h4>
      <div className="treatment-cases">
        <div className="quadrant">
          <h4 className="treatment-case">Zirconia:</h4>
          <img className="rct-images" src={Zirconia} alt="Zirconia" />
          <h3 className="treatment-priceMK">Market Price:<p>Rs. 8,000</p></h3>
          <h3 className="treatment-priceUD">Our Price: <p>Rs. 5,999</p></h3>
        </div>
        <div className="quadrant">
          <h4 className="treatment-case">Ceramic:</h4>
          <img className="rct-images" src={Ceramic} alt="Ceramic" />
          <h3 className="treatment-priceMK">Market Price: <p>Rs. 5,0000</p></h3>
          <h3 className="treatment-priceUD">Our Price: <p>Rs. 3,999</p></h3>
        </div>
        <div className="quadrant">
          <h4 className="treatment-case">Metal:</h4>
          <img className="rct-images" src={Metal} alt="Metal" />
          <h3 className="treatment-priceMK">Market Price: <p>Rs. 2,500</p></h3>
          <h3 className="treatment-priceUD">Our Price: <p>Rs. 1,749</p></h3>
        </div>
      </div>
    </>
  );
  const aligneritems = (
    <>
      <h3 className=" treatment-specialist">Specialist: Orthodontist</h3>
      <div className="treatment-comparision-item">
        <h3 className="treatment-priceMK-head">Market Price: <p>Rs. 80,000</p></h3>
        <h3 className="treatment-priceUD-head">Our Price: <p>Rs. 59,999</p></h3>
        <hr className="treatments-hr"/>
      </div>
      <div className="Aligner-image">
        <img src={aligner} alt="Aligner" />
      </div>
      <p className="treatment-description">Aligners and braces are orthodontic devices used to sraighten teeth. They are used in cases such as - </p>
      <ul className="treatment-points">
        <li >Almost invisible</li>
        <li >Comfortable to wear</li>
        <li >Easy to clean</li>
      </ul>
    </>
  );
  const surgicalitems = (
    <>
      <p style={{fontSize:"16px"}}> We offer a range of surgical procedures such as:</p>
      <h2 className="treatments-surgical">Wisdom Tooth Impactions</h2>
        <h3 className="treatment-specialist">Specialist: Oral Maxillofacial Surgeon</h3>
        <h3 className="treatment-priceMK-head">Market Price: <p>Rs. 6,000</p> </h3>
        <h3 className="treatment-priceUD-head">Our Price: <p>Rs. 4,499</p></h3>
        <hr className="treatments-hr"/>
        <img className="surgical-images" src={impaction} alt="impaction" />
        <p className="treatment-description">An impacted wisdom tooth occurs when your third molar or
        wisdom tooth becomes trapped in your jawbone or gums. This might cause infections and cavities 
        in your second molar, hence the wisdom tooth is removed using a surgical procedure. 
        The treatment is done under local anesthesia to make it painless.
        </p>
      <br />
      <h2 className="treatments-surgical">Oral Implants</h2>
        <h3 className="treatment-specialist">Specialist: Prosthodontist</h3>
        <h3 className="treatment-priceMK-head">Market Price: <p>Rs. 35,000</p> </h3>
        <h3 className="treatment-priceUD-head">Our Price: <p>Rs. 29,999</p></h3>
        <hr className="treatments-hr"/>
        <img className="surgical-images" src={implant} alt="Implant" />
        <p className="treatment-description">A dental implant is a metal post that 
        replaces the root portion of a missing tooth. 
        An artificial tooth (crown) is placed on an extension 
        of the dental implant, giving you the look of a real tooth.
        </p>
      <br />
      <h2 className="treatments-surgical">Extractions and Fillings</h2>
        <h3 className="treatment-specialist">Specialist: Dental Surgeon</h3>
        <h3 className="treatment-priceMK-head">Market Price: <p>Rs. 2,000</p> </h3>
        <h3 className="treatment-priceUD-head">Our Price: <p>Rs. 1,499</p></h3>
        <hr className="treatments-hr"/>
        <img className="surgical-images" src={extraction} alt="Extraction" />
        <p className="treatment-description">
          Surgical procedure to remove a tooth in case of decay or when the tooth cannot be saved.
        </p>
    </>
  );

  const tabitems = [
    {
      key: "RootCanal",
      label: "Root Canal",
      children: rctitems,
      id: "RootCanal",
    },
    {
      key: "Aligners",
      label: "Aligners",
      children: aligneritems,
      id: "Aligners",
    },
    {
      key: "Surgical",
      label: "Surgical Procedures",
      children: surgicalitems,
      id: "Surgical",
    },
  ];

  return (
    <>
      <div className="treatments-section">
        <div className="treatments-section-title">
          <h3 className="treatments-title">
            <span>Treatments</span>
          </h3>
          <p className="treatments-title-description">
            We bring experienced Doctors at your convenience, available at your
            nearest location, specializing in what you need
          </p>
        </div>
        <div className="treatments-section-content">
          <div className="treatments-section-tabs">
            <section className="treatments-section-tab">
              <Tabs
                activeKey={tab}
                defaultActiveKey={tab}
                className="treatments-tabs"
                style={{
                  height: "100%",
                }}
                items={tabitems}
                tabPosition={tabPosition}
                onChange={onChange}
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default TreatmentsComponent;
