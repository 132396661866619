import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faCalendarCheck,
  faPhone,
  faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../Assets/UP-DOC-logo.svg";
import { useUserAuth } from "../contexts/AuthContext.js";
import { getAuth, signOut } from "firebase/auth";

function Navbar() {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  const openLogin = () => {
    setNav(!nav);
    navigate("/login");
  };

  const openLoginDesktop = () => {
    navigate("/login");
  };
  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate("/login");
  };

  const { user } = useUserAuth();

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <Link to="/#hero">
          <img src={logo} alt="UpDoc Logo" className="logo" />
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/treatments#RootCanal" className="navbar-links">
            Treatments
          </Link>
        </li>
        <li>
          <Link to="/faq" className="navbar-links">
            FAQ
          </Link>
        </li>
        <li>
          <Link to="/about" className="navbar-links">
            About
          </Link>
        </li>
        {user ? (
            <>
              <li>
                <Link to="/dashboard" className="navbar-links">
                  Dashboard
                </Link>
              </li>
              <li>
                <a>
                  <button
                    className="Login-btn"
                    type="button"
                    onClick={handleLogout}
                  >
                    <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                  </button>
                </a>
              </li>
            </>

          ) : (
            <li>
              <a>
                <button className="Login-btn" type="button" onClick={openLoginDesktop}>
                  <FontAwesomeIcon icon={faRightFromBracket} /> Login
                </button>
              </a>
            </li>
          )}
        
      </ul>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/treatments#RootCanal">
              Treatments
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/faq">
              FAQ
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/about">
              About
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/#contact">
              Contact
            </Link>
          </li>
          {user ? (
            <>
              <li>
                <Link onClick={openNav} to="/dashboard">
                  Dashboard
                </Link>
              </li>
              <li>
                <a>
                  <button
                    className="Login-btn"
                    type="button"
                    onClick={handleLogout}
                  >
                    <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                  </button>
                </a>
              </li>
            </>

          ) : (
            <li>
              <a>
                <button className="Login-btn" type="button" onClick={openLogin}>
                  <FontAwesomeIcon icon={faRightFromBracket} /> Login
                </button>
              </a>
            </li>
          )}
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;
