import {collection, getDocs, getDoc, doc} from 'firebase/firestore';
import { database } from '../../../firebase.js';
import { format, set } from 'date-fns';


export const fetchData= async(ph)=>{
    let upcoming_docs=[];
    let previous_docs=[];
    let time=""
    let hasAppointment=false;
    if(ph!==''){
        const collectionref=collection(database,"ApplicationData/"+String(ph)+"/Appointments");
        const doc_refs=await getDocs(collectionref);
        doc_refs.forEach(appointment=>{
            if(appointment.data()['time'].split(':')[0].length===1){
                time="0"+appointment.data()['time'];
            }
            else{
                time=appointment.data()['time'];
            }
            if(appointment.data()['date']>format(Date.now(),"yyyy-MM-dd")){
                upcoming_docs.push({id:appointment.id,...appointment.data()});
            }
            else if(appointment.data()['date']<format(Date.now(),"yyyy-MM-dd")){
                previous_docs.push({id:appointment.id,...appointment.data()});
            }
            else if(time.slice(0,6)>format(Date.now(),"hh:mma")){
                upcoming_docs.push({id:appointment.id,...appointment.data()});
            }
            else{
                previous_docs.push({id:appointment.id,...appointment.data()});
            }
        });
        if(upcoming_docs.length+previous_docs.length>0) hasAppointment=true;
        const docref=doc(collection(database,"ApplicationData"),ph);
        let data=await getDoc(docref);
        return [hasAppointment,upcoming_docs,previous_docs,data.data()['firstName'],data.data()['lastName']];
    }
}