import React, { useState, useEffect } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import OtpInput from "otp-input-react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import "../../Styles/Login.css";

export default function Login() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [otpTime, setOtpTime] = useState(40);

  useEffect(() => {
    if (otpTime > 0) {
      const intervalId = setInterval(() => {
        setOtpTime(otpTime - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [otpTime]);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => { },
        }
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;
    if (formatPh.length !== 13) {
      toast.error("Please enter a valid phone number");
      setLoading(false);
      return;
    }
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent to your phone number");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  function resendOtp(){
    setOtpTime(30);
    onSignup();
  }
  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Invalid OTP")
        setLoading(false);
      });
  }
  function sendWhatsappMsg() {
    const formatPh = "+919686672555";
    window.open(
      `https://api.whatsapp.com/send?phone=${formatPh}&text=Hi,%20I%20would%20like%20to%20book%20an%20appointment.%20`)
  }

  return (
    <>
    <div style={{display:"flex", justifyContent:"center"}}>
      <Card style={{border:'none', width:'400px'}} >
        <Card.Body className="Login-Card">
          <h2 className="Login-Title">Log In</h2>
          <div id="recaptcha-container"></div>
          {showOTP?(
          <Form className="Login-Form">
            <Form.Group id="otp">
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    disabled={false}
                    autoFocus
                    className="otp-container"
                  />
            </Form.Group>
            <div className="Btn-Container">
            <Button disabled={loading} onClick={onOTPVerify} className="Login-Button" type="submit">
              Verify OTP
            </Button>
            <Button disabled={loading} onClick={resendOtp} className="Login-Button" type="submit">
              Resend OTP{otpTime > 0 && ` in ${otpTime} secs`}
            </Button>
            </div>
          </Form>
        ):(
          <Form className="Login-Form">
            <Form.Group id="phoneNumber">
              <Form.Label><PhoneInput country={"in"} value={ph} onChange={setPh} /></Form.Label>
            </Form.Group>
            <div className="Btn-Container">
            <Button disabled={loading} onClick={onSignup} className="Login-Button" type="submit">
              Send OTP
            </Button>
            </div>
          </Form>
        )}
        </Card.Body>
      </Card>
      </div>
      <div className="New-User">
        New User? <Link to="/">Book Appointment</Link>
      </div>

    </>
  );
}
