import React, { useEffect } from 'react'
import "./styles/clientInfo.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faCalendarCheck, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { database } from '../../../firebase.js';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, getDocs, doc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { format, set } from 'date-fns';
import { fetchData } from './FetchData.js';
import UpcomingFrames from './UpcomingFrames.js';
import PreviousFrames from './PreviousFrames.js';

const ClientInfo = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [status, setStatus] = useState("");
    const [remarks, setRemarks] = useState("");
    const [TreatmentPlan, setTreatmentPlan] = useState("");
    const auth = getAuth();
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false);
    const [upcoming,SetUpcoming]=useState([]);
    const [previous, setPrevious]=useState([]);
    const [hasAppointment,setHasAppointment]=useState(true);


    onAuthStateChanged(auth, async (user) => { if (user) Caller(); });



    const handleLogout = () => {
        signOut(getAuth());
        navigate("/login");
    };
    const handleBookAppointmentClick = () => {
        navigate("/appointment");
    };
    const handleDirections = () => {
        window.open("https://maps.app.goo.gl/CMmS7ARte4S895Cm7", "_blank");
    };
    const Caller = async (e) => {
        setPhoneNumber(
            auth.currentUser.phoneNumber.replace(/\D/g, "").slice(-10)
        );
        if(phoneNumber!==''){
            setLoading(true);
            const res =await fetchData(phoneNumber);
            setHasAppointment(res[0]);
            SetUpcoming(res[1]);
            setPrevious(res[2]);
            setFirstName(res[3]);
            setLastName(res[4]);
            setLoading(false);
        }
    };
    return (
        <div className="Container">
            {hasAppointment ? (
                <>
                    {/*loading && <p>loading...</p>*/}
                    <div className="Client-info">
                        <div className="Client-info-title">
                            <h1>
                                <FontAwesomeIcon icon={faUser} />
                                &nbsp;{firstName + " " + lastName}
                            </h1>
                            <button onClick={handleLogout}>
                                LogOut &nbsp;
                                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                            </button>
                        </div>
                        <div className="Client-info-content">
                            {/* Upcoming color Code #9de6a7 previous color code #cacaca */}
                            <div className="Client-info-status">
                                <h3>Status: </h3>
                                {status == "check-up" ? <h4>CHECK-UP</h4> : <h4>Check-up</h4>}
                                {status == "treatment" ? <h4>TREATMENT</h4> : <h4>Treatment</h4>}
                                {status == "followup" ? <h4>FOLLOW UP</h4> : <h4>Follow up</h4>}
                            </div>
                            <div className="Client-info-remarks">
                                <h3>Remarks: </h3>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <h4>{remarks}</h4>
                            </div>
                            <div className="Client-info-remarks">
                                <h3>Treatment Plan: </h3>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <h4>{TreatmentPlan}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="Upcoming-Appointment-info">
                            <h2>Upcoming Appointment</h2>
                            <ul>
                                {upcoming.length>0&& upcoming.map(application=>(<UpcomingFrames application={application}/>))}
                            </ul>
                        </div>
                    <button className="Dashboard-BookApp-button" onClick={handleBookAppointmentClick}>
                        <FontAwesomeIcon icon={faCalendarCheck} />
                        &nbsp; Book Another Appointment
                    </button>
                    <hr />
                    <div className="Previous-Appointment-Conainer">
                        <h2>Previous Appointments</h2>
                        {previous.length>0&& previous.map(application=>(<PreviousFrames application={application}/>))}
                    </div>
                </>
            ) : (
                <>
                    <div className="Client-info">
                        <div className="Client-info-title">
                            <h1>
                                <FontAwesomeIcon icon={faUser} />
                                &nbsp;{firstName + " " + lastName}
                            </h1>
                            <button onClick={handleLogout}>
                                LogOut &nbsp;
                                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                            </button>
                        </div>
                        <div className="Client-info-content">
                            {/* Upcoming color Code #9de6a7 previous color code #cacaca */}
                            <div className="Client-info-status">
                                <h3>Status: </h3>
                                <h4>No Appointment Booked</h4>
                            </div>
                            <button className="Dashboard-BookApp-button" onClick={handleBookAppointmentClick}>
                                <FontAwesomeIcon icon={faCalendarCheck} bounce />
                                &nbsp; Book Free Appointment
                            </button>
                        </div>
                    </div>

                </>
            )}
        </div>
    );

};

export default ClientInfo;
