import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "../Styles/BookAppointment.css";

function BookAppointment() {
  const navigate = useNavigate();

  return (
    <div className="ba-section">
      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Why Choose UpDoc</span>
        </h3>
        <p className="ba-description">
         Discover the reasons to choose UpDoc for your dental 
         needs. Experience expert care, convenience, and personalized 
         solutions while we make your well-being and quality our 
         top priority.
        </p>

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#283B8A" }} /> Specialized Dentists for each treatment
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#283B8A" }} /> Quality treatments with affordability
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#283B8A" }} /> Choose the nearest experience center
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#283B8A" }} /> Easy and Quick appointment booking
        </p>
      </div>
    </div>
  );
}

export default BookAppointment;
