import React from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import InformationCard from "./InformationCard";
import "../Styles/Info.css";
import rootCanalIcon from "../Assets/icons/icons8-root-canal-32.png";
import alignersIcon from "../Assets/icons/icons8-braces-32.png";
import surgeryIcon from "../Assets/icons/icons8-tooth-implant-32.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom"

function Info() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
          infinite: false,
          dots: false
        }
      }
    ]
  };

  function openInfoBtn() {
    document.getElementById("infoLinkPopup").style.display = "block";
  }
  function closeInfoBtn() {
    document.getElementById("infoLinkPopup").style.display = "none";
  }
  function urlParam() {
    const parsed = queryString.parse(window.location.search);
    const value = parsed.key; // Retrieve the value of a specific query parameter
  
  }
  const location = useLocation();

  const parsed = queryString.parse(location.search);
  console.log(typeof(parsed.token));
  const itemId = parsed.token;
  console.log(itemId)

  return (
    <div className="info-section" id="services">
      {/* <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
        Explore our treatments that will help you achieve your desired smile and oral health.
        </p>
      </div> */}
      <div>
        <div className="info-Laptop" >
          <div className="info-grid">
            <div className="info-cards-content" id="rct" type="button">
              {
              itemId=='rct' ? (
                <Link to={"/treatments#RootCanal"}>
                  <InformationCard
                    title="Root Canals"
                    starting
                    marketPrice="₹5,500/-"
                    our
                    ourPrice="0"
                    icon={rootCanalIcon}
                  />
                </Link>
              ) : (
                <Link to={"/treatments#RootCanal"}>
                  <InformationCard
                    title="Root Canals"
                    starting
                    marketPrice="₹5,500/-"
                    our
                    ourPrice="₹3,999/-"
                    icon={rootCanalIcon}
                  />
                </Link>
              )}

            </div>
            <div className="info-cards-content" id="aligners" type="button" >
              <Link to={"/treatments#Aligners"}>
                <InformationCard
                  title="Aligners"
                  starting
                  marketPrice="₹80,000/-"
                  our
                  ourPrice="₹59,999/-"
                  icon={alignersIcon}
                />
              </Link>
            </div>
            <div className="info-cards-content" id="surgery" type="button">
              <Link to={"/treatments#Surgical"}>
                <InformationCard
                  title="Surgical Procedures"
                  starting
                  marketPrice="₹6,000/-"
                  our
                  ourPrice="₹4,499/-"
                  icon={surgeryIcon}
                />
              </Link>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Info;
