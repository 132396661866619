import React from "react";
import AppointmentForm from "../Components/AppointmentForm";
import Navbar from "../Components/Navbar";

function Appointment() {
  return (
    <>
      <Navbar />
      <AppointmentForm />
    </>
  );
}

export default Appointment;
