//import neccessary libraries
import { database } from "../firebase.js";
import { collection, doc, getDocs, onSnapshot, query } from "firebase/firestore";
import { timings } from "./Dashboard/data/timings.js";
import { format } from "date-fns";

//Function
export default function Blocker(location,date) {
    //Variables
    let availableTimings=timings;
    //Open Up Slots
    for(let k in availableTimings){
        availableTimings[k].status="Open";
    }
    if(date==''){
        date=format(Date.now(),"dd-MM-yyyy").toString();
    }
    let modifiedDate=date;
    if(date.includes('/')){
        modifiedDate = date.replace(/\//g, "-");
    }
    //Check Database entries based on date and slot
    const Reader = async () => {
        try{
            //Access Firestore
            await getDocs(collection(doc(collection(database, "Scheduled Appointments"),location),modifiedDate)).then((querySnapshot)=>{               
                const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id }));                
                for (let i = 0; i < newData.length; i++) {
                    if(newData[i].counter==2 || newData[i].type=="treatment"){
                        availableTimings[newData[i].time].status="Closed";
                    }
                }
            });
        }catch(error){
            console.log(error);
        }
    }
    Reader();
    return availableTimings;
}
