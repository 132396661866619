import React from "react";
import { useLocation } from "react-router-dom";

function NotFoundComponent() {
  const deployedURL = window.location.href;
  const location = useLocation();

  return (
    <div style={{padding:"80px 10px" }}>
      <h1 style={{fontWeight:"bold"}}>Page Not Found</h1>
      <p style={{margin:"20px 0"}}>The requested URL "{deployedURL}" was not found on this server.</p>
      <h2 style={{fontWeight:"bold"}}>Please head back to <a href="/">Home Page</a></h2>
    </div>
  );
}

export default NotFoundComponent;
