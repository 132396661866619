import React from "react";
import Navbar from "../Components/Navbar";
import ClientInfo from "../Components/Dashboard/Client/ClientInfo";


function DashboardClient() {
  return (
    <>
        <Navbar />
        <ClientInfo />
    </>
  );
}

export default DashboardClient;

