import React  from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-description">
              Talk to online doctors and get medical advice, online
              prescriptions, refills and medical notes within minutes. On-demand
              healthcare services at your fingertips.
            </p>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Treatments</p>
          <ul className="ft-list-items">
            <li>
              <a href="/treatments#RootCanal">Root Canal</a>
            </li>
            <li>
              <a href="/treatments#Surgical">Implants</a>
            </li>
            <li>
              <a href="/treatments#Aligners">Aligners</a>
            </li>
            <li>
              <a href="/treatments#Surgical">Extractions</a>
            </li>
            <li>
              <a href="/treatments#Surgical">Impactions</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/legal"}>General Info</Link>
            </li>
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/legal"}>Terms of Services</Link>
            </li>
            <li>
              <Link to={"/legal"}>Consultations</Link>
            </li>
            <li>
              <Link to={"/legal"}>How it Works</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:support@updoc.in">support@updoc.in</a>
            </li>
            <li>
              <a href="mailto:appointment@updoc.in">
                appointment@updoc.in
              </a>
            </li>
            <li>
              <a href="tel:+91 9618927087">+91 9618927087</a>
            </li>
            <li>
              <a href="tel:+91 9618927087">+91 9845046872</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Join Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:info@updoc.in?subject=To enroll as Clinician &body=Hi,%0AI would like to enroll as a clinician to rent out a chair in my clinic.%0AThank you.">Enroll as Clinician</a>
            </li>
            <li>
              <a href="mailto:info@updoc.in?subject=To enroll as Specialist &body=Hi,%0AI would like to enroll as a specialist with UpDoc.%0AThank you.">Enroll as Specialist</a>
            </li>
            <li>
              <a href="mailto:info@updoc.in?subject=To enroll as Clinician &body=Hi,%0AI would like to enroll as a BDS Surgeon with UpDoc.%0AThank you.">Enroll as BDS Surgeon</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 UpDoc Healthcare Solutions. All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.instagram.com/updoc_health/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} style={{fontSize: "14px"}}/>
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61558848804061&mibextid=ZbWKwL"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
