import React, { useState } from "react";
import "../Styles/AppointmentForm.css";
import { database, auth } from "../firebase.js";
import { collection, addDoc, getDocs, doc, setDoc, deleteField, updateDoc } from "firebase/firestore";
import Blocker from "./AppointmentScheduler.js";
import { timings } from "./Dashboard/data/timings.js";
import { format } from "date-fns";
import FormControl from "@material-ui/core/FormControl";
import { ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import Checkbox from '@mui/material/Checkbox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AppointmentForm() {
  const navigate = useNavigate();
  /*const [formErrors, setFormErrors] = useState({});*/
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    Phno: '',
    Age: '',
    Problem: '',
    ProblemDesc: '',
    date: '',
    Time: '',
    location: '',
    email: '',
    code: '',
    mode: ''
  }
  );

  const [availableTimings, setAvailableTimings] = useState(timings);
  const [checkValue, setCheckValue] = useState(false);
  onAuthStateChanged(auth, (u) => { if (u) { user.Phno = auth.currentUser.phoneNumber.replace(/\D/g, "").slice(-10); } });

  const BlockTimings = (location, date) => {
    if (date === '') { date = Date.now() };
    let modifiedDate = format(date, "dd-MM-yyyy");
    let modified_availableTimings = Blocker(location, modifiedDate);
    setAvailableTimings(modified_availableTimings);
    console.log(availableTimings);
  }
  const SubmitForm = async (e) => {
    e.preventDefault();
    let { firstName, lastName, Phno, Age, Problem, ProblemDesc, date, Time, location, email, mode } = user;
    if (firstName === '') {
      alert("Please enter your first name");
      return;
    }
    else if (lastName === '') {
      alert("Please enter your last name");
      return;
    }
    else if (Age === '') {
      alert("Please enter your age");
      return;
    }
    else if (Problem === '') {
      alert("Please enter your problem");
      return;
    }
    else if (mode === '') {
      alert("Please select the mode");
      return;
    }
    else if ((mode === 'online') && email === '') {
      alert("Please fill in email");
      return;
    }
    else if ((mode === 'offline') && location === '') {
      alert("Please select desired location");
      return;
    }
    else if (date === '') {
      alert("Please enter the date");
      return;
    }
    else if (Time === '') {
      alert("Please enter the time");
      return;
    }
    else if (checkValue === false) {
      alert("Please accept the terms and conditions");
      return;
    }
    else {
      if (location === "") {
        location = "Online";
      }
      console.log(firstName, lastName, Phno, Age, Problem, date, Time, location, email, mode)
      try {
        let flag_user = 0;
        const docRef = await getDocs(collection(database, 'ApplicationData')).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          for (let i = 0; i < newData.length; i++) {
            if (newData[i].id === Phno) {
              flag_user = 1;
              addDoc(collection(doc(collection(database, 'ApplicationData'), Phno), 'Appointments'), {
                date: date,
                time: Time,
                doctor: '',
                problem: Problem,
                problemDescription: ProblemDesc,
                location: location,
                email: email,
                mode: mode,
                price: '',
                remarks: '',
                status: '',
                treatmentPlan: ''
              });
            }
          }
        });
        if (flag_user === 0) {
          setDoc(doc(collection(database, "ApplicationData"), Phno), {
            firstName: firstName,
            lastName: lastName,
            Age: Age,
          });
          addDoc(collection(doc(collection(database, 'ApplicationData'), Phno), 'Appointments'), {
            date: date,
            time: Time,
            doctor: '',
            problem: Problem,
            location: location,
            email: email,
            mode: mode,
            price: '',
            remarks: '',
            status: '',
            treatmentPlan: ''
          });
        }
        let newDate = format(date, "dd-MM-yyyy");
        newDate = newDate.toString();
        //get dates from Scheduled appointments
        let flag_date = 0;
        let flag_time = 0;
        await getDocs(collection(doc(collection(database, "Scheduled Appointments"), location), newDate)).then((querySnapshot) => {
          const newData2 = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          console.log(newData2);
          if (newData2.length === 0) {
            addDoc(collection(doc(collection(database, 'Scheduled Appointments'), location), newDate), {
              counter: 1,
              type: "checkup",
              time: Time
            });
          }
          else {
            for (let i = 0; i < newData2.length; i++) {
              if (newData2[i].time === Time) {
                console.log(newData2[i].id);
                flag_time = 1;
                setDoc(doc(collection(doc(collection(database, 'Scheduled Appointments'), location), newDate), newData2[i].id), {
                  counter: 2,
                  type: "checkup",
                  time: Time
                });
              }
            }
            if (flag_time === 0) {
              addDoc(collection(doc(collection(database, 'Scheduled Appointments'), location), newDate), {
                counter: 1,
                type: "checkup",
                time: Time
              });
            }
          }
        });
        alert("Successfully Sent Appointment Request!");
        //refresh firebase connection
        navigate('/dashboard');
      } catch (e) {
        alert("Our Servers are down at the moment, Please try Later");
        console.log(e);
      }
    }
  }

  return (
    <div className="appointment-form-section">
      <div className="container">
        <div className="brand-title">UpDoc Book Appointment Form
          <h1 className="form-heading" style={{ paddingTop: "12px", background: "rgb(146 156 196)" }}>
            <p>Book your FREE appointment now</p>
          </h1>
        </div>
        <form action="">
          <div className="inputs">
            <label>Name</label>
            <div className="name-fields-container">
              <input
                type="text"
                value={user.firstName}
                placeholder="first"
                required
                onChange={(e) => setUser({ ...user, firstName: e.target.value })}
              />
              <input
                type="text"
                placeholder="last"
                value={user.lastName}
                required
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
              />
            </div>
            {/* <label>Phone number</label>
          <input
            type="text"
            value={user.Phno}
            required
            onChange={(e) => setUser({ ...user, Phno: e.target.value })}
          /> */}
            <label>Age</label>
            <input
              type="text"
              value={user.Age}
              placeholder="eg. 31"
              required
              onChange={(e) => setUser({ ...user, Age: e.target.value })}
            />
            <label>What is your Complaint? </label>
            <select
              className="Problem-select"
              value={user.Problem}
              placeholder=" Please describe your problem"
              onChange={(e) => setUser({ ...user, Problem: e.target.value })}
              required
            >
              <option value="default">Select</option>
              <option value="Tooth Pain">Tooth Pain</option>
              <option value="Crowding">Crowding</option>
              <option value="Wisdom Tooth Pain">Wisdom Tooth Pain</option>
              <option value="Cavities">Cavities</option>
              <option value="Sensitivity">Sensitivity</option>
              <option value="Tooth Decay">Tooth Decay</option>
              <option value="Gum Bleeding">Gum Bleeding</option>
              <option value="Other">Other</option>
            </select>
            <label>Would you like to add anything?</label>
            <input
              type="text"
              // className="Problem-select"
              value={user.ProblemDesc}
              placeholder=" Please describe your problem"
              onChange={(e) => setUser({ ...user, ProblemDesc: e.target.value })}
              required
            />
            {/* <option value="default">Select</option>
              <option value="Crowding">Crowding</option>
              <option value="Wisdom Tooth Pain">Wisdom Tooth Pain</option>
              <option value="Cavities">Cavities</option>
            </select> */}
            <div className="Consultation-Mode-Container">
              <label>Consultation Mode</label>
              <RadioGroup
                className="BookAppointment-radio-group-Method-Select"
                value={user.mode}
                onChange={(e) => setUser({ ...user, mode: e.target.value })}
                required
              >
                <FormControlLabel
                  value="online"
                  control={<Radio />}
                  label="Online"
                >
                </ FormControlLabel >
                <FormControlLabel
                  value="offline"
                  control={<Radio />}
                  label="Offline"
                />
              </RadioGroup>
              {user.mode === "offline" ? (
                <>
                  <label>Location</label>
                  <RadioGroup
                    className="BookAppointment-radio-location-select"
                    value={user.location}
                    onChange={(e) => { setUser({ ...user, location: e.target.value }); BlockTimings(e.target.value, user.date); if (user.Time !== '') { setUser({ ...user, location: e.target.value, Time: '' }) }; }}
                    required
                  >
                    <FormControlLabel value="Kondapur" control={<Radio />} label="Kondapur" />
                    <FormControlLabel value="Vidyanagar" control={<Radio />} label="Vidyanagar" />
                    <FormControlLabel value="Ram Koti" control={<Radio />} label="Ram Koti" />
                  </RadioGroup>
                </>
              ) : (
                <>
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter email"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                    required
                  />
                </>
              )}
            </div>

            <label>Coupon Code</label>
            <input
              type="text"
              value={user.code}
              onChange={(e) => setUser({ ...user, code: e.target.value })}
            />
            <label>Date</label>
            <DatePicker
              selected={user.date}
              value={user.date}
              minDate={format(Date.now(), 'yyyy-MM-dd')}
              filterDate={(date) => {
                const day = date.getDay();
                if (user.location === '' && (user.mode === '' || user.mode === 'offline')) {
                  return false;
                }
                else if (user.mode === 'offline' && user.location === 'Kondapur') {
                  return day !== 1;
                }
                else if (user.mode === 'offline' && (user.location === 'Vidyanagar' || user.location === 'Ram Koti')) {
                  return day !== 0;
                }
                else {
                  return true;
                }
              }}
              onChange={(e) => { setUser({ ...user, date: format(e, 'yyyy-MM-dd')}); BlockTimings(user.location, format(e, 'yyyy-MM-dd')); if (user.Time !== '') { setUser({ ...user, date: format(e, 'yyyy-MM-dd'), Time: '' }) }; }}
              required
            />
            {user.mode === 'offline' && user.location == "Kondapur" ? (
              <p>Please Note, Kondapur is Closed on Mondays</p>
            ) : (user.mode === 'offline' && user.location == "Vidyanagar" ? (<p>Please Note, Vidyanagar is Closed on Sundays</p>
            ) : (
              user.mode === 'offline' && user.location == "Ram Koti" ? (
                <p>Please Note, Ram Koti is Closed on Sundays</p>
              ) : (<p></p>)))
            }
            <label>Time</label>
            <div>
              <FormControl component="fieldset">
                {user.date === format(Date.now(), 'yyyy-MM-dd') ? (
                  <ToggleButtonGroup className="TimingsGrid" aria-label="time" name="timeslots" value={user.Time} onChange={(e) => { setUser({ ...user, Time: e.target.value }) }}>
                    {/* {"10:00" > format(Date.now(), 'HH:mm') && availableTimings["10:00AM"].status === "Open" && <ToggleButton value={"10:00AM"} label={"10:00AM"} >10:00AM</ToggleButton>} */}
                    {/* {"10:30" > format(Date.now(), 'HH:mm') && availableTimings["10:30AM"].status === "Open" && <ToggleButton value={"10:30AM"} label={"10:30AM"} >10:30AM</ToggleButton>} */}
                    {"11:00" > format(Date.now(), 'HH:mm') && availableTimings["11:00AM"].status === "Open" && <ToggleButton value={"11:00AM"} label={"11:00AM"} >11:00AM</ToggleButton>}
                    {"11:30" > format(Date.now(), 'HH:mm') && availableTimings["11:30AM"].status === "Open" && <ToggleButton value={"11:30AM"} label={"11:30AM"} >11:30AM</ToggleButton>}
                    {"12:00" > format(Date.now(), 'HH:mm') && availableTimings["12:00PM"].status === "Open" && <ToggleButton value={"12:00PM"} label={"12:00PM"} >12:00PM</ToggleButton>}
                    {"12:30" > format(Date.now(), 'HH:mm') && availableTimings["12:30PM"].status === "Open" && <ToggleButton value={"12:30PM"} label={"12:30PM"} >12:30PM</ToggleButton>}
                    {"13:00" > format(Date.now(), 'HH:mm') && availableTimings["1:00PM"].status === "Open" && <ToggleButton value={"1:00PM"} label={"1:00PM"} >1:00PM</ToggleButton>}
                    {"13:30" > format(Date.now(), 'HH:mm') && availableTimings["1:30PM"].status === "Open" && <ToggleButton value={"1:30PM"} label={"1:30PM"} >1:30PM</ToggleButton>}
                    {"14:00" > format(Date.now(), 'HH:mm') && availableTimings["2:00PM"].status === "Open" && <ToggleButton value={"2:00PM"} label={"2:00PM"} >2:00PM</ToggleButton>}
                    {"14:30" > format(Date.now(), 'HH:mm') && availableTimings["2:30PM"].status === "Open" && <ToggleButton value={"2:30PM"} label={"2:30PM"} >2:30PM</ToggleButton>}
                    {"15:00" > format(Date.now(), 'HH:mm') && availableTimings["3:00PM"].status === "Open" && <ToggleButton value={"3:00PM"} label={"3:00PM"} >3:00PM</ToggleButton>}
                    {"15:30" > format(Date.now(), 'HH:mm') && availableTimings["3:30PM"].status === "Open" && <ToggleButton value={"3:30PM"} label={"3:30PM"} >3:30PM</ToggleButton>}
                    {"16:00" > format(Date.now(), 'HH:mm') && availableTimings["4:00PM"].status === "Open" && <ToggleButton value={"4:00PM"} label={"4:00PM"} >4:00PM</ToggleButton>}
                    {"16:30" > format(Date.now(), 'HH:mm') && availableTimings["4:30PM"].status === "Open" && <ToggleButton value={"4:30PM"} label={"4:30PM"} >4:30PM</ToggleButton>}
                    {"17:00" > format(Date.now(), 'HH:mm') && availableTimings["5:00PM"].status === "Open" && <ToggleButton value={"5:00PM"} label={"5:00PM"} >5:00PM</ToggleButton>}
                    {"17:30" > format(Date.now(), 'HH:mm') && availableTimings["5:30PM"].status === "Open" && <ToggleButton value={"5:30PM"} label={"5:30PM"} >5:30PM</ToggleButton>}
                    {"18:00" > format(Date.now(), 'HH:mm') && availableTimings["6:00PM"].status === "Open" && <ToggleButton value={"6:00PM"} label={"6:00PM"} >6:00PM</ToggleButton>}
                    {"18:30" > format(Date.now(), 'HH:mm') && availableTimings["6:30PM"].status === "Open" && <ToggleButton value={"6:30PM"} label={"6:30PM"} >6:30PM</ToggleButton>}
                    {"19:00" > format(Date.now(), 'HH:mm') && availableTimings["7:00PM"].status === "Open" && <ToggleButton value={"7:00PM"} label={"7:00PM"} >7:00PM</ToggleButton>}
                    {"19:30" > format(Date.now(), 'HH:mm') && availableTimings["7:30PM"].status === "Open" && <ToggleButton value={"7:30PM"} label={"7:30PM"} >7:30PM</ToggleButton>}
                    {"19:30" < format(Date.now(), 'HH:mm') && <p>No more Slots left today</p>}
                  </ToggleButtonGroup>
                ) : (
                  <ToggleButtonGroup className="TimingsGrid" aria-label="time" name="timeslots" value={user.Time} onChange={(e) => { setUser({ ...user, Time: e.target.value }) }}>
                    {/* {availableTimings["10:00AM"].status === "Open" && <ToggleButton value={"10:00AM"} label={"10:00AM"} >10:00AM</ToggleButton>} */}
                    {/* {availableTimings["10:30AM"].status === "Open" && <ToggleButton value={"10:30AM"} label={"10:30AM"} >10:30AM</ToggleButton>} */}
                    {availableTimings["11:00AM"].status === "Open" && <ToggleButton value={"11:00AM"} label={"11:00AM"} >11:00AM</ToggleButton>}
                    {availableTimings["11:30AM"].status === "Open" && <ToggleButton value={"11:30AM"} label={"11:30AM"} >11:30AM</ToggleButton>}
                    {availableTimings["12:00PM"].status === "Open" && <ToggleButton value={"12:00PM"} label={"12:00PM"} >12:00PM</ToggleButton>}
                    {availableTimings["12:30PM"].status === "Open" && <ToggleButton value={"12:30PM"} label={"12:30PM"} >12:30PM</ToggleButton>}
                    {availableTimings["1:00PM"].status === "Open" && <ToggleButton value={"1:00PM"} label={"1:00PM"} >1:00PM</ToggleButton>}
                    {availableTimings["1:30PM"].status === "Open" && <ToggleButton value={"1:30PM"} label={"1:30PM"} >1:30PM</ToggleButton>}
                    {availableTimings["2:00PM"].status === "Open" && <ToggleButton value={"2:00PM"} label={"2:00PM"} >2:00PM</ToggleButton>}
                    {availableTimings["2:30PM"].status === "Open" && <ToggleButton value={"2:30PM"} label={"2:30PM"} >2:30PM</ToggleButton>}
                    {availableTimings["3:00PM"].status === "Open" && <ToggleButton value={"3:00PM"} label={"3:00PM"} >3:00PM</ToggleButton>}
                    {availableTimings["3:30PM"].status === "Open" && <ToggleButton value={"3:30PM"} label={"3:30PM"} >3:30PM</ToggleButton>}
                    {availableTimings["4:00PM"].status === "Open" && <ToggleButton value={"4:00PM"} label={"4:00PM"} >4:00PM</ToggleButton>}
                    {availableTimings["4:30PM"].status === "Open" && <ToggleButton value={"4:30PM"} label={"4:30PM"} >4:30PM</ToggleButton>}
                    {availableTimings["5:00PM"].status === "Open" && <ToggleButton value={"5:00PM"} label={"5:00PM"} >5:00PM</ToggleButton>}
                    {availableTimings["5:30PM"].status === "Open" && <ToggleButton value={"5:30PM"} label={"5:30PM"} >5:30PM</ToggleButton>}
                    {availableTimings["6:00PM"].status === "Open" && <ToggleButton value={"6:00PM"} label={"6:00PM"} >6:00PM</ToggleButton>}
                    {availableTimings["6:30PM"].status === "Open" && <ToggleButton value={"6:30PM"} label={"6:30PM"} >6:30PM</ToggleButton>}
                    {availableTimings["7:00PM"].status === "Open" && <ToggleButton value={"7:00PM"} label={"7:00PM"} >7:00PM</ToggleButton>}
                    {availableTimings["7:30PM"].status === "Open" && <ToggleButton value={"7:30PM"} label={"7:30PM"} >7:30PM</ToggleButton>}
                  </ToggleButtonGroup>
                )}
              </FormControl>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Checkbox value={checkValue} onChange={(e) => { if (e.target.checked) { setCheckValue(true); } else { setCheckValue(false); } }} /> I agree to UpDoc's <a href="/legal" style={{ textDecoration: 'none' }}>Terms and Conditions</a>.
            </div>
            <button type="submit" className="Submit-btn-bookAppointment" onClick={SubmitForm}>
              Confirm Appointment
            </button>
          </div>
        </form>
      </div>
    </div>
  );

}

export default AppointmentForm;
