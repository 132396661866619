import React from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Info from "../Components/Info";
import About from "../Components/About";
import BookAppointment from "../Components/BookAppointment";
import Reviews from "../Components/Reviews";
import Doctors from "../Components/Doctors";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <title>UpDoc | Dentist near me | Dental Clinic near me</title>
        <meta name="description" content="Discover top-tier dental care with UpDoc! Our platform connects you with local Dental Specialists, ensuring high-quality treatments at affordable prices. Benefit from our asset-lite model and specialist-exclusive services. Smile brighter with UpDoc!" />
        <meta name="keywords" content="updoc, doctor near me, dentist near me, Best dentist in kondapur, Aligners, Root canals, Orthodontics, Crowns, Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants, Equipment, Crowns, Whitening, Implants" />
        <meta name="author" content="UpDoc" />
        <link rel="canonical" href="https://updoc.in/" />
      </Helmet>
      <Navbar />
      <Hero />
      <Info />
      <BookAppointment />
      <Doctors />
      <Reviews />
      <Footer />
    </div>
  );
}

export default Home;

