import React from "react";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          UpDoc is a pioneering oral healthcare platform dedicated
          to revolutionizing the way people access affordable,
          high-quality dental care in India.
          By seamlessly connecting patients with
          trusted dental professionals,
          UpDoc aims to ensure that oral health becomes a
          priority for everyone, regardless of their background or location.
          Our innovative approach not only improves access to essential
          dental services but also empowers dentists and clinics to
          thrive in today's dynamic healthcare landscape.
        </p>

        <SolutionStep
          title="Our Mission"
          description="At UpDoc, we believe that good oral health is fundamental to overall well-being and should be within reach of every individual. Our mission is simple yet profound – to make oral care affordable, accessible, and of the highest quality for everyone."
        />

        <SolutionStep
          title="Patient-Centric Approach:"
          description="At UpDoc we put patients first. We understand the challenges individuals face in accessing dental care, which is why we've designed our platform to be user-friendly, transparent, and convenient. With UpDoc, you're in control of your oral health journey from appointment to treatment and Aftercare."
        />

        <SolutionStep
          title="Trusted Dental Professionals:"
          description="We partner with a network of experienced and reputable dentists who are committed to providing exceptional care. Whether you need a routine check-up or treatments, You can trust UpDoc to connect you with the right dental expert."
        />
        <SolutionStep
          title="Affordability and Quality Assurance:"
          description="We believe that affordability should never compromise quality. UpDoc is dedicated to offering cost-effective solutions without compromising on the standard of care. Rest assured, your oral health is in good hands with UpDoc. "
        />
        <SolutionStep
          title="Convenience and Accessibility:"
          description="Say goodbye to long wait times and tedious appointment scheduling. With UpDoc, you can book appointments online, access qualified teleconsultations, and receive personalized treatment plans – all from the comfort of your home or office."
        />
      </div>
    </div>
  );
}

export default About;
