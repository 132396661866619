import React from 'react'
import Navbar from '../Components/Navbar'
import Doctor_Page from '../Components/Doctor_PageComponent';
import { Helmet } from'react-helmet';

const Doctors = () => {
    return (
        <div>
            <Helmet>    
                <title>UpDoc Doctors</title>
                <meta name="description" content="UpDoc is a platform that allows you to book appointments with doctors" />
                <meta name="keywords" content="updoc, doctor near me, dentist near me, Best dentist in kondapur, Aligners, Root canals, Orthodontics, Crowns, Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment, Dental Crowns, Dental Whitening, Dental Implants, Dental Equipment" />
                <meta name="author" content="UpDoc" />
            </Helmet>
            <Navbar />
            <div style={{ paddingTop: '80px' }}>
                <Doctor_Page />
            </div>
        </div>
    );
};

export default Doctors