import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserAuth } from "../../contexts/AuthContext";
const ProtectedRoute = ({ children }) => {
  const { user } = useUserAuth();
  if (!user) {
    return <Navigate to="/" />;
  }
  return children ? children : <Outlet />;

};

export default ProtectedRoute;
