import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import OtpInput from "otp-input-react";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../firebase.js";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import "../../Styles/LoginPhno.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function Login() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [otpTime, setOtpTime] = useState(40);

  useEffect(() => {
    if (otpTime > 0) {
      const intervalId = setInterval(() => {
        setOtpTime(otpTime - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [otpTime]);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => { },
        }
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;
    if (formatPh.length !== 13) {
      toast.error("Please enter a valid phone number");
      setLoading(false);
      return;
    }
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent to your phone number");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  function resendOtp(){
    setOtpTime(30);
    onSignup();
  }
  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        navigate("/appointment");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Invalid OTP")
        setLoading(false);
      });
  }
  function sendWhatsappMsg() {
    window.open(
      `https://wa.me/919686672555&text=Hi,%20I%20would%20like%20to%20book%20an%20appointment.%20`)
  }

  return (
    <section className="popup-section">
      <div>
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container"></div>
        {user ? (
          <h2 className="text-center text-white font-medium text-2xl">
            👍Login Success
          </h2>
        ) : (
          <div className="popup-section">
            {showOTP ? (
              <>
                <label
                  htmlFor="otp"
                  className="enter-otp-text"
                >
                  Enter your OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="otp-container "
                />
                <button
                  onClick={onOTPVerify}
                  className="otp-verify-btn"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>
                <button
                  disabled={otpTime>0}
                  onClick={resendOtp}
                  className="resend-otp-btn"
                  type="button"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <a>Resend OTP{otpTime > 0 && ` in ${otpTime} secs`}</a>
                </button>
              </>
            ) : (
              <>
              <div className="popup-text-container">
                <label
                  htmlFor=""
                  className="popup-text"
                >
                  Book Free Online consultation or Clinic visit
                </label>
              
                <div className="phone-input-container">
                  <div className="phone-input-field">
                    <PhoneInput country={"in"} value={ph} onChange={setPh} />
                  </div>
                  <div className="phone-input-OTP-button">
                    <button
                      onClick={onSignup}
                      className="send-button"
                    >
                      {loading && (
                        <CgSpinner size={20} className="mt-1 animate-spin" />
                      )}
                      <span className="send-button-text">Get OTP</span>
                    </button>
                  </div>

                </div>
                <p style={{textAlign:"center", color:"aliceblue", marginBottom:"5px"}}>or</p>
                <div className="call-section">
                  <button className="call-button" >
                    <FontAwesomeIcon icon={faPhone} fade/> <a href="tel:+919686672555">&nbsp; Call us </a>
                  </button>
                  <button className="call-button-whatsapp" onClick={sendWhatsappMsg}>
                    <FontAwesomeIcon icon={faWhatsapp} bounce style={{height:"22px"}}/> &nbsp; Whatsapp 
                  </button>
                </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};