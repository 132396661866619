import {format} from "date-fns";
function UpcomingFrames(props){
    const {application} = props;
    const handleDirections = () => {
        window.open("https://maps.app.goo.gl/CMmS7ARte4S895Cm7", "_blank");
    };
    return(
        <li key={application.id}>
            <div className="Client-Appointment-info">
                <div className="Appointment-info-Date">
                    <p>
                        <b>Date:</b> {format(application.date, "dd-MM-yyyy")}
                    </p>
                    <p>
                        <b>Time:</b> {application.time}
                    </p>
                </div>
                <div className="Appointment-info-Doctor">
                    <p>Doctor: {application.doctorName}</p>
                    <p>Problem: {application.problem}</p>
                </div>
            </div>
            <div className="Appointment-info-Location">
                <p>
                    <b>Location: {application.location}</b>
                </p>
                <button onClick={handleDirections}>Get Directions!</button>
            </div>
        </li>
    );
}
export default UpcomingFrames;